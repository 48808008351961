import React from 'react';
import { Link } from 'gatsby';

const ArticleListItem = ({ key, title, to, itemClass }) =>
	<li className={itemClass} key={key}>
			<Link to={to.href}>
					{title}
			</Link>
	</li>

export default ArticleListItem;
