import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
// import { Location } from "@reach/router";
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n";
import { StaticQuery, graphql } from "gatsby";
import { IntlProvider } from "react-intl";
import "intl";

import "../../assets/sass/natureintransition.scss";

const Layout = ({ children, langKey, location, i18nMessages, lang }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
						languages {
							defaultLangKey
							langs
						}
					}
				}
				underneathJson {
					title
					content {
						childMarkdownRemark {
							html
							rawMarkdownBody
						}
					}
					content_is {
						childMarkdownRemark {
							html
							rawMarkdownBody
						}
					}
					artists {
						name
						country
						to {
							href
							label
						}
					}
				}
				natureintransitionJson {
					title
					content {
						childMarkdownRemark {
							html
							rawMarkdownBody
						}
					}
					content_is {
						childMarkdownRemark {
							html
							rawMarkdownBody
						}
					}
					articles {
						title
						to {
							title
							href
						}
					}
					artists {
						name
						country
						to {
							href
							label
						}
					}
				}
			}
		`}
		render={data => {
			const url = location.pathname;
			const { langs, defaultLangKey } = data.site.siteMetadata.languages;
			const langKey = getCurrentLangKey(langs, defaultLangKey, url);
			const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/");
			const lang = langKey;
			const langsMenu = getLangs(
				langs,
				langKey,
				getUrlForLang(homeLink, url)
			).map(item => ({
				...item,
				link: item.link.replace(`/${defaultLangKey}/`, "/")
			}));
			return (
				<IntlProvider locale={langKey} messages={i18nMessages} location={location} lang={lang}>
					<Helmet
						title={data.site.siteMetadata.title}
						meta={[
							{ name: "description", content: "Digital catalogue of exhibitions at The Nordic House" },
							{ name: "keywords", content: "site, web" }
						]}
					>
						<html lang="en" />
					</Helmet>
					<Header
						artists={data.natureintransitionJson.artists}
						articleLinks={data.natureintransitionJson.articles}
						langs={langsMenu}
						langKey={langKey}
						location={location}
					/>
					{children}
					<Footer lang={lang} />
				</IntlProvider>
			);
		}}
	/>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
