import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import MenuButton from "./MenuButton";
import Menu from "./Menu";
// import LanguageSelector from './LanguageSelector';

const Header = ({ langs, langsMenu, langKey, location, artists, articleLinks }) => {

	let langCode = "/" + langKey;
	let isEn = false;
	if (langCode === "/en") {
		langCode = "/"
		isEn = true;
	}

	const [menuActive, toggleMenu] = useState( false)

	return (
		
		<header id="header" className="block w-full h-18">
			<div className="w-full h-full block relative bg-white z-50 py-2">
			<Link className="title sr-only" to={isEn ? '/' : '/is/'}>
				{isEn ? "Nature in Transition" : "Í síkvikri mótun"}
			</Link>
			<MenuButton langs={langs} langKey={langKey} toggle={toggleMenu} isActive={menuActive} />
				{/* <LanguageSelector classNames="languageSelector" langs={langsMenu} locale={langKey} lang={langKey} location={location} langKey={langKey} /> */}
				</div>
			<Menu langs={langs} langKey={langKey} isActive={menuActive} artists={artists} articleLinks={articleLinks} />
		</header>
	);};

Header.propTypes = {
	articleLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Header;
