import React from 'react';

const Footer = ({ lang, langKey, location }) => {
	const language = lang;
	let isEn = false;
	if (language === "en") {
		isEn = true;
	}
	const content = {
		copy: { en: "The exhibition is a collaboration between the Iceland University of the arts (IUA) and the Nordic House in Reykjavík, conceived of as a contribution of the IUA to the Reykjavík Congress of the Universities of the Arctic (UArctic) network. The initiative is supported by grants from the Nordic Council of Ministers and from the Nordic Culture Fund.", 
		is: "Í síkvikri mótun" },
	}
	return (
		<footer id="footer" className="wrapper">
			<div className="inner">
					{/* <p className="max-w-screen-sm">{ isEn ? content.copy.en : content.copy.is }</p> */}
				<p className="max-w-screen-sm">
					The exhibition is a collaboration between the Iceland University of the arts (IUA) and the Nordic House in Reykjavík, conceived of as a contribution of the IUA to the Reykjavík Congress of the Universities of the Arctic (UArctic) network. The initiative is supported by grants from the Nordic Council of Ministers and from the Nordic Culture Fund.
				</p>
				<p className="max-w-screen-sm">
					Curatorial and research assistance was provided by students in the Creating and Curating Exhibitons course at the IUA / UI: Daníel Perez Eðvarsson, Friðrik Hjartar, Heiðrún Sif Magnúsdóttir, Mariane Sól Úlfarsdóttir, Sunna Dagsdóttir, Þorsteinn Freyr Fjölnisson
				</p>
			</div>
		</footer>
	)
};

export default Footer;
