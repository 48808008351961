import React from "react";
// import PropTypes from "prop-types";
import { Link } from "gatsby";

import ArtistListItem from "../artists/ArtistListItem";
import ArticleListItem from "../articles/ArticleListItem";

const Menu = ({ langs, langKey, isActive, artists, articleLinks}) => {
	let langCode = "/" + langKey;
	let isEn = false;
	if (langCode === "/en") {
		langCode = "/"
		isEn = true;
	}
	const content = {
		home: { en: "Home", is: "Heim" },
		articles: { en: "Articles", is: "Greinar" },
		artistconvos: { en: "Artist conversations", is: "Samtöl við listamen" },
		artists: { en: "About the Artists and their work", is: "Um listamennina og verk þeirra" },
	}

	// const [menuActive, setMenuActive] = useState( false)
  // const toggleMenu = () => setMenuActive(!menuActive);
  // const menuOn = () => setMenuActive(true);
  // const menuOff = () => setMenuActive(false);


	return (
		<div id="main-menu" className={`${isActive ? 'isActive' : ''}`}>
      <nav className="navigation">
				{/* <pre>{JSON.stringify(langCode)}</pre> */}
				<ul>
					{/* {articleLinks.map(link => (
						<li key={link.name}>
							<Link to={`${langCode}${link.to.href}`}>{link.name}</Link>
						</li>
					))} */}
					<li><Link to={ isEn ? '/' : '/is/' }>{isEn ? content.home.en : content.home.is}</Link>
					</li>
					{/* <li><Link to={isEn ? '/exhibitions/nature-in-transition/articles/' : '/is/exhibitions/nature-in-transition/articles/'}>{isEn ? content.articles.en : content.articles.is}</Link>
					</li> */}

					{articleLinks.map(article => (
						<ArticleListItem key={article.title} {...article} />
					))}
					{/* <li>
						<Link to={isEn ? '/exhibitions/nature-in-transition/artist-conversations/' : '/is/exhibitions/nature-in-transition/artist-conversations/'}>{isEn ? content.artistconvos.en : content.artistconvos.is}</Link>
					</li> */}
					<li>
						<Link to={isEn ? '/exhibitions/nature-in-transition/artists/' : '/is/exhibitions/nature-in-transition/artists/'}>{isEn ? content.artists.en : content.artists.is}</Link>
						<ul>
						{artists.map(artist => (
							<ArtistListItem key={artist.name} {...artist} />
						))}
						</ul>
					</li>
				</ul>

      </nav>
	</div>
)
}

export default Menu;