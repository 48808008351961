import React from 'react';
import { Link } from 'gatsby';

const ArtistListItem = ({ name, to, lang, langKey, location }) => {

	const language = lang;
	let isEn = false;
	if (language === "en") {
		isEn = true;
	}

	return (
		<li className="mb-4">
			<Link to={ isEn ? to.href : `${to.href}` } >
				{name}
			</Link>
		</li>
)}
;
export default ArtistListItem;
