import React from "react";

const MenuButton = ({ toggle, isActive }) => {
  return (
    <button onClick={() => toggle(!isActive)}  >
			<div className={`text-2xl uppercase ${isActive ? 'isActive' : ''}`}>{isActive ? 'Close' : 'Menu'}</div>
    </button>
  )
}
export default MenuButton
